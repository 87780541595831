body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 100%;
}

html {
    height: 100%;
}

body {
    color: #ffffff;
    height: 100%;
}

.button:hover, .button:active, .button:visited {
    color: #ffffff;
}

.container {
    min-height: 100vh;
    background: #23376d url(/static/media/wood-bg.d7268d71.jpg) center top fixed;
}

.wrapper {
    padding-bottom: 50px;
}

.button-primary {
    padding: 0;
    display: inline-block;
    width: 100%;
    border-radius: 8px;
    background-color: #f3715a;
    border: none;
    margin: 0px 0 16px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    height: 48px;
    line-height: 48px;
    text-transform: uppercase;
}

h1 {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.6px;
    text-align: center;
    color: #734d20;
    margin-bottom: 12px;
}

.gallery-empty {
    margin: 0 32px;
    padding: 60px 18px;
    border-radius: 8px;
    background-color: #FFFFFF;
    color: #23376d;
    text-align: center;
}

.gallery-empty-alert {
    width: 72px;
    height: 72px;
    margin-bottom: 22px;
}

.gallery-empty p {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

.close-button {
    display: block;
    margin: 0 auto;
}

.loader {
    width: 60px;
    height: 60px;
    border: 5px solid #999;
    border-bottom-color: transparent;
    border-radius: 50%;
    margin: 20px auto;
    -webkit-animation: loader 500ms linear infinite;
            animation: loader 500ms linear infinite;
}

@-webkit-keyframes loader {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes loader {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.button-intro {
    min-width: 120px;
    width: auto;
    padding: 0 16px;
    position: absolute;
    right: 8px;
    bottom: 16px;
    margin: 0;
}

.char {
    width: 180px;
    height: 230px;
    object-fit: contain;
    display: block;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
}

.video-react .video-react-big-play-button {
    font-size: 8em;
    background: transparent;
    border: none;
}

.video, .video video {
    border-radius: 8px;
}

.fortunes {
    margin-left: 32px;
    margin-right: 32px;
}

video {
    object-fit: cover;
}

header {
    text-align: center;
    height: 224px;
    margin-bottom: 8px;
}

header .logo {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-187.5px);
            transform: translateX(-187.5px);
    width: 375px;
    height: 224px;
    background: transparent url(/static/media/logo-fortune-big-en.4fd89d20.svg) center no-repeat;
}

.zh-Hans header .logo {
    background: transparent url(/static/media/logo-fortune-big-sc.2e133270.svg) center no-repeat;
}

.zh-Hant header .logo {
    background: transparent url(/static/media/logo-fortune-big.cc2a9c68.svg) center no-repeat;
}

.en header .logo {
    background: transparent url(/static/media/logo-fortune-big-en.4fd89d20.svg) center no-repeat;
}


header .header-background {
    position: absolute;
    top: 0;
    height: 216px;
    left: 0;
    right: 0;
    border-bottom: 4px #ffffff solid;
    background-blend-mode: multiply;
    background: rgba(115, 77, 32, 0.6);
}

.video-wrapper {
    border-radius: 8px;
    position: relative;
}

video {
    display: block;
}

.video-control {
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.btn_play {
    width: 40px;
    height: 44px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-20px, -22px);
            transform: translate(-20px, -22px);
    background: transparent url(/static/media/btn-play.e946ba5c.svg);
    border: none;
}

.video-wrapper--border video {
    border: #ffffff solid 4px;
}

.video-wrapper--border {
    margin-bottom: 16px;
}

.video-control .title {
    position: absolute;
    right: 12px;
    bottom: 12px;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    width: auto;
    color: #ffffff;
}

.wrapper-intro {
    padding-bottom: 16px;
}

.intro-title {
    text-align: center;
    letter-spacing: 2px;
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: bold;
}

.intro-content {
    margin: 0 32px;
}

.intro-text {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 16px;
}

.intro-instructions-wrapper {
    padding: 16px;
    border-radius: 8px;
    background-color: #ffffff;
    color: #734d20;
}

.intro-instructions {
    list-style-type: none;
}

.intro-instruction {
    border: #e8e8e8 solid 1px;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 16px;
    position: relative;
    text-align: center;
}

.intro-instruction-icon {
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto 16px;
}

.intro-instruction-number {
    top: 16px;
    left: 16px;
    position: absolute;
    font-size: 32px;
    color: #cbced6;
}

.intro-noid {
    padding: 16px;
    border-radius: 8px;
    background-color: rgba(115, 77, 32, 0.6);
    margin-bottom: 16px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    background-blend-mode: multiply;
}

.vday .intro-noid {
    background-color: #000000;
}

.intro-noid-icon {
    display: block;
    width: 44px;
    height: 44px;
    margin: 0 auto 8px;
}

.intro-subtitle {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 16px;
    margin-bottom: 16px;
}

