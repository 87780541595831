header {
    text-align: center;
    height: 224px;
    margin-bottom: 8px;
}

header .logo {
    position: relative;
    left: 50%;
    transform: translateX(-187.5px);
    width: 375px;
    height: 224px;
    background: transparent url("./images/logo-fortune-big-en.svg") center no-repeat;
}

.zh-Hans header .logo {
    background: transparent url("./images/logo-fortune-big-sc.svg") center no-repeat;
}

.zh-Hant header .logo {
    background: transparent url("./images/logo-fortune-big.svg") center no-repeat;
}

.en header .logo {
    background: transparent url("./images/logo-fortune-big-en.svg") center no-repeat;
}


header .header-background {
    position: absolute;
    top: 0;
    height: 216px;
    left: 0;
    right: 0;
    border-bottom: 4px #ffffff solid;
    background-blend-mode: multiply;
    background: rgba(115, 77, 32, 0.6);
}
