.wrapper-intro {
    padding-bottom: 16px;
}

.intro-title {
    text-align: center;
    letter-spacing: 2px;
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: bold;
}

.intro-content {
    margin: 0 32px;
}

.intro-text {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 16px;
}

.intro-instructions-wrapper {
    padding: 16px;
    border-radius: 8px;
    background-color: #ffffff;
    color: #734d20;
}

.intro-instructions {
    list-style-type: none;
}

.intro-instruction {
    border: #e8e8e8 solid 1px;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 16px;
    position: relative;
    text-align: center;
}

.intro-instruction-icon {
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto 16px;
}

.intro-instruction-number {
    top: 16px;
    left: 16px;
    position: absolute;
    font-size: 32px;
    color: #cbced6;
}

.intro-noid {
    padding: 16px;
    border-radius: 8px;
    background-color: rgba(115, 77, 32, 0.6);
    margin-bottom: 16px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    background-blend-mode: multiply;
}

.vday .intro-noid {
    background-color: #000000;
}

.intro-noid-icon {
    display: block;
    width: 44px;
    height: 44px;
    margin: 0 auto 8px;
}

.intro-subtitle {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 16px;
    margin-bottom: 16px;
}
