.video-wrapper {
    border-radius: 8px;
    position: relative;
}

video {
    display: block;
}

.video-control {
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.btn_play {
    width: 40px;
    height: 44px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-20px, -22px);
    background: transparent url("./images/btn-play.svg");
    border: none;
}

.video-wrapper--border video {
    border: #ffffff solid 4px;
}

.video-wrapper--border {
    margin-bottom: 16px;
}

.video-control .title {
    position: absolute;
    right: 12px;
    bottom: 12px;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    width: auto;
    color: #ffffff;
}
