html {
    height: 100%;
}

body {
    color: #ffffff;
    height: 100%;
}

.button:hover, .button:active, .button:visited {
    color: #ffffff;
}

.container {
    min-height: 100vh;
    background: #23376d url("./images/wood-bg.jpg") center top fixed;
}

.wrapper {
    padding-bottom: 50px;
}

.button-primary {
    padding: 0;
    display: inline-block;
    width: 100%;
    border-radius: 8px;
    background-color: #f3715a;
    border: none;
    margin: 0px 0 16px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    height: 48px;
    line-height: 48px;
    text-transform: uppercase;
}

h1 {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.6px;
    text-align: center;
    color: #734d20;
    margin-bottom: 12px;
}

.gallery-empty {
    margin: 0 32px;
    padding: 60px 18px;
    border-radius: 8px;
    background-color: #FFFFFF;
    color: #23376d;
    text-align: center;
}

.gallery-empty-alert {
    width: 72px;
    height: 72px;
    margin-bottom: 22px;
}

.gallery-empty p {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

.close-button {
    display: block;
    margin: 0 auto;
}

.loader {
    width: 60px;
    height: 60px;
    border: 5px solid #999;
    border-bottom-color: transparent;
    border-radius: 50%;
    margin: 20px auto;
    animation: loader 500ms linear infinite;
}

@keyframes loader {
    to {
        transform: rotate(360deg);
    }
}

.button-intro {
    min-width: 120px;
    width: auto;
    padding: 0 16px;
    position: absolute;
    right: 8px;
    bottom: 16px;
    margin: 0;
}

.char {
    width: 180px;
    height: 230px;
    object-fit: contain;
    display: block;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
}

.video-react .video-react-big-play-button {
    font-size: 8em;
    background: transparent;
    border: none;
}

.video, .video video {
    border-radius: 8px;
}

.fortunes {
    margin-left: 32px;
    margin-right: 32px;
}

video {
    object-fit: cover;
}
